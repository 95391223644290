import { useCallback, useContext, useState } from "react";
import { AccountsContext } from "../accounts/AccountsContext";
import { Account } from "../accounts/types";
import { useBalances } from "../balances/useBalances";
import { List } from "../components/List";
import { SignMessageModal } from "../modals/SignMessageModal";
import { TransferAmountModal } from "../modals/TransferAmountModal";
import { useConnectWallet, useWallets } from "@subwallet-connect/react";

export const AccountsPage = () => {
  const { accounts } = useContext(AccountsContext);
  useBalances();

  const accountsArray = Array.from(accounts.values());
  const [currentAccount, setCurrentAccount] = useState<Account>();
  const [transferAmountIsVisible, setTransferAmountIsVisible] = useState(false);
  const [signMessageIsVisible, setSignMessageIsVisible] = useState(false);
  const [createAccountIsVisible, setCreateAccountIsVisible] = useState(false);

  const onSend = useCallback((account: Account) => () => {
    setCurrentAccount(account);
    setTransferAmountIsVisible(true);
  }, []);

  const onCloseTransferAmount = useCallback(() => {
    setCurrentAccount(undefined);
    setTransferAmountIsVisible(false);
  }, []);

  const onSignMessage = useCallback((account: Account) => () => {
    setCurrentAccount(account);
    setSignMessageIsVisible(true);
  }, []);

  const onCreateAccountClick = useCallback(() => {
    setCreateAccountIsVisible(true);
  }, []);

  const onCloseSignMessage = useCallback(() => {
    setCurrentAccount(undefined);
    setSignMessageIsVisible(false);
  }, []);

  const onCloseCreateAccount = useCallback(() => {
    setCreateAccountIsVisible(false);
  }, []);

  const [{ wallet }, connect,disconnect] = useConnectWallet();
  const connectedWallets = useWallets();

  const disconnectWallet = () =>  wallet && disconnect({label: wallet?.label, type: wallet?.type});


  return (
    <div className="page">
      {/* <div className="top-bar">
      <button onClick={onCreateAccountClick}>Create local account</button>
    </div> */}

      <div className="top-bar">
        {connectedWallets?.length > 0 ? (
          <button className="button-wix" onClick={disconnectWallet}>
            <span className="button-text">Disconnect Polkadot</span>
          </button>
        ) : (
          <button className="button-wix" onClick={() => connect()}>
            <span className="button-text">Connect Polkadot</span>
          </button>
        )}
      </div>
      <TransferAmountModal
        isVisible={transferAmountIsVisible}
        sender={currentAccount}
        onClose={onCloseTransferAmount}
      />
      <SignMessageModal
        isVisible={signMessageIsVisible}
        account={currentAccount}
        onClose={onCloseSignMessage}
      />
    </div>
  );
}