import { Chain } from './sections';
import { Evm } from './sections/evm';

import type {
  IClient,
  ICollection,
  IToken,
  Options,
  IExtrinsics,
  IChain,
  IEvm,
} from './types';

import { Subscription } from './sections/subscriptions';
import { ThinClient } from './thin-client';

export type { SocketClient, Subscription } from './sections/subscriptions';

export class Sdk extends ThinClient implements IClient {
  /**
   * @deprecated use common
   */
  public readonly chain: IChain;

  /**
   * @deprecated use extrinsic
   */
  public readonly extrinsics: IExtrinsics;

  /**
   * @deprecated use collection
   */
  public readonly collections: ICollection;

  /**
   * @deprecated use token
   */
  public readonly tokens: IToken;

  public readonly evm: IEvm;

  public readonly subscription: Subscription;

  /**
   * @deprecated use subscription
   */
  public readonly subscriptions: Subscription;

  public readonly defaults: Options = {
    baseUrl: '',
  };

  constructor(public readonly options: Options) {
    super(options);

    this.evm = new Evm(this, 'evm');
    this.subscription = new Subscription(this);

    this.chain = new Chain(this, 'chain');
    this.extrinsics = this.extrinsic;
    this.collections = this.collection;
    this.tokens = this.token;
    this.subscriptions = this.subscription;
  }
}

/**
 * @deprecated use Sdk
 */
export const Client = Sdk;

export * from './types';

export * from './common/constants';
