import { Section } from '../common';
import {
  SignTxResultResponse,
  UnsignedTxPayloadBody,
  SubmitTxBody,
  SubmitResultResponse,
  ExtrinsicResultResponse,
  FeeResponse,
  ExtrinsicResultRequest,
  GetExtrinsicQuery,
  GetExtrinsicResponse,
  Method,
  SdkTxBuildBody,
  Account,
  Signer,
  IExtrinsics,
  Options,
} from '../types';
import { sleep } from '../common/utils';

const getSigner = (
  options: Options,
  accountOrSigner?: Account | Signer,
): Signer | undefined => {
  if (accountOrSigner) {
    return 'sign' in accountOrSigner
      ? (accountOrSigner as Signer)
      : (accountOrSigner as Account).signer;
  }

  if (options.signer) return options.signer;
  if (options.account?.signer) return options.account.signer;

  return undefined;
};

const getAddress = (
  options: Options,
  args: { address?: string },
): string | undefined => {
  if (args.address) return args.address;
  if (options.signer?.address) return options.signer.address;
  if (options.account?.address) return options.account.address;
  if (options.account?.signer?.address) return options.account.signer.address;

  return undefined;
};

export class Extrinsic extends Section implements IExtrinsics {
  async build(args: SdkTxBuildBody): Promise<UnsignedTxPayloadBody> {
    const address = getAddress(this.client.options, args);

    if (!address) throw new Error('Invalid address');

    const response = await this.client.instance({
      method: Method.POST,
      baseURL: this.baseUrl,
      url: 'build',
      data: {
        ...args,
        address,
      },
    });

    return response.data;
  }

  async getFee(
    args: SdkTxBuildBody | UnsignedTxPayloadBody | SubmitTxBody,
  ): Promise<FeeResponse> {
    const response = await this.client.instance({
      method: Method.POST,
      baseURL: this.baseUrl,
      url: 'calculate-fee',
      data: args,
    });
    return response.data;
  }

  async sign(
    args: UnsignedTxPayloadBody,
    signer?: Account | Signer,
  ): Promise<SignTxResultResponse> {
    const signerToUse = getSigner(this.client.options, signer);

    if (!signerToUse) throw new Error(`No signer provided`);

    return signerToUse.sign(args);
  }

  async submit(args: SubmitTxBody): Promise<SubmitResultResponse> {
    const response = await this.client.instance({
      method: Method.POST,
      baseURL: this.baseUrl,
      url: 'submit',
      data: args,
    });
    return response.data;
  }

  async submitWatch(
    args: SdkTxBuildBody,
    signer = this.client.options.signer,
  ): Promise<SubmitResultResponse> {
    const txBuild = await this.client.extrinsic.build(args);

    const signedTxPayload = await this.client.extrinsic.sign(txBuild, signer);

    const submitTxResult = await this.client.extrinsic.submit({
      signerPayloadJSON: txBuild.signerPayloadJSON,
      signature: signedTxPayload.signature,
    });

    return submitTxResult;
  }

  async submitWaitResult(
    args: SdkTxBuildBody,
    signer = this.client.options.signer,
  ): Promise<ExtrinsicResultResponse<any>> {
    const { hash } = await this.client.extrinsic.submitWatch(args, signer);

    let checkStatusResult: ExtrinsicResultResponse<any> | undefined;
    let i = 0;
    while (
      (!checkStatusResult || !checkStatusResult?.isCompleted) &&
      i <= this.client.options.maximumNumberOfStatusRequests
    ) {
      i += 1;
      // eslint-disable-next-line no-await-in-loop
      checkStatusResult = await this.client.extrinsic.status({ hash });
      if (checkStatusResult.isCompleted || checkStatusResult.error) {
        return checkStatusResult;
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(this.client.options.waitBetweenStatusRequestsInMs);
    }
    throw new Error();
  }

  async status(
    args: ExtrinsicResultRequest,
  ): Promise<ExtrinsicResultResponse<any>> {
    const response = await this.client.instance({
      method: Method.GET,
      baseURL: this.baseUrl,
      url: 'status',
      params: args,
    });
    return response.data;
  }

  async get(args: GetExtrinsicQuery): Promise<GetExtrinsicResponse> {
    const response = await this.client.instance({
      method: Method.GET,
      baseURL: this.baseUrl,
      url: '',
      params: args,
    });
    return response.data;
  }
}
