import { BaseClient } from './common/base-client';
import {
  IBalance,
  ICollection,
  ICommon,
  IERC721,
  IFungible,
  IIpfs,
  IRefungible,
  IStateQueries,
  IToken,
  Options,
} from './types';
import {
  Balance,
  Collection,
  ERC721,
  Fungible,
  Ipfs,
  Refungible,
  Token,
} from './sections';
import { Common } from './sections/common';
import { StateQueries } from './sections/state-queries';

export class ThinClient extends BaseClient {
  public readonly common: ICommon;

  public readonly balance: IBalance;

  public readonly fungible: IFungible;

  public readonly refungible: IRefungible;

  public readonly collection: ICollection;

  public readonly token: IToken;

  public readonly erc721: IERC721;

  public readonly ipfs: IIpfs;

  public readonly stateQuery: IStateQueries;

  public readonly defaults: Options = {
    baseUrl: '',
  };

  constructor(public readonly options: Options) {
    super(options);

    this.common = new Common(this, 'common');
    this.balance = new Balance(this, 'balance');
    this.fungible = new Fungible(this, 'fungible');
    this.refungible = new Refungible(this, 'refungible');
    this.collection = new Collection(this, 'collections');
    this.token = new Token(this, 'tokens');
    this.erc721 = new ERC721(this, 'erc721');
    this.ipfs = new Ipfs(this, 'ipfs');
    this.stateQuery = new StateQueries(this, 'query');
  }
}

export * from './common/constants';
