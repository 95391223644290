import { EvmSendArguments } from '@unique-nft/common/types/evm';
import {
  createMutationByInstance,
  MutationAbstract,
} from '../../../common/mutation';
import {
  EvmSendResultParsed,
  IEvm,
  Method,
  MutationOptions,
} from '../../../types';
import { parseEvmEvents } from '../utils';

class EvmSendMutation extends MutationAbstract<
  EvmSendArguments,
  EvmSendResultParsed
> {
  constructor(private readonly evm: IEvm) {
    super(evm.client, Method.POST, 'evm/send');
  }

  public override async submitWaitResult(args, options: MutationOptions) {
    const result = await super.submitWaitResult(args, options);

    if ('abi' in args) {
      if (result.parsed.isExecutedFailed) {
        await this.evm.call(args);
      }

      const { parsedEvents, unknownEvents } = parseEvmEvents(
        result.events,
        args.abi,
      );

      result.parsed.parsedEvents = parsedEvents;
      result.parsed.unknownEvents = unknownEvents;
    }

    return result;
  }
}
export function createEvmSendMutation(evm: IEvm) {
  const mutation = new EvmSendMutation(evm);
  return createMutationByInstance(mutation);
}
