export enum Rooms {
  SYSTEM = 'system',
  BLOCKS = 'blocks',
  EXTRINSICS = 'extrinsics',
  COLLECTION = 'collection',
  CONTRACT = 'contract',
  EVENTS = 'events',
  BALANCE = 'balance',
  ACCOUNT_CURRENT_BALANCE = 'account-current-balance',
}

export enum SubscriptionEvents {
  SYSTEM = 'system',
  BLOCKS = 'blocks',
  EXTRINSICS = 'extrinsics',
  EVENTS = 'events',
  CONTRACT_LOGS = 'contract-logs',
  COLLECTIONS = 'collections',
  HAS_NEXT = 'has-next',
  BALANCES = 'balances',
  ACCOUNT_CURRENT_BALANCE = 'account-current-balance',
  SUBSCRIBE_STATE = 'subscribe-state',
}

export interface Room {
  name: Rooms;
  data: {
    [key: string]: string;
  };
}

export interface BlockFilter {
  fromBlock?: number;
}

export interface ExtrinsicFilter extends BlockFilter {
  signer?: string | '*';
}

export interface CollectionFilter extends ExtrinsicFilter {
  collectionId?: number | '*';
}

export interface BalancesFilter extends ExtrinsicFilter {
  address?: string | '*';
}

export interface AccountCurrentBalanceFilter {
  address?: string | '*';
}

export interface ContractFilter extends ExtrinsicFilter {
  address?: string | '*';
}

export interface EventsFilter extends ExtrinsicFilter {
  section?: string | '*';
  method?: string | '*';
}

export interface ContractLog {
  address: string;
  topics: Array<any>;
  data: string;
}

export enum BalanceMethods {
  WITHDRAW = 'Withdraw',
  TRANSFER = 'Transfer',
  DEPOSIT = 'Deposit',
}

export interface BalancesParsedData {
  method: BalanceMethods;
  from?: string;
  to?: string;
  amount: string;
}
