import { Section } from '../common';
import {
  PropertyPermissionsResponse,
  SetPropertyPermissionsParsed,
  SetCollectionPropertiesParsed,
  DeleteCollectionPropertiesParsed,
  CollectionPropertiesResponse,
  SetCollectionPropertiesBody,
  DeleteCollectionPropertiesBody,
  SetPropertyPermissionsBody,
  CollectionInfoWithSchemaResponse,
  CreateCollectionBody,
  CreateCollectionParsed,
  SetCollectionLimitsBody,
  SetCollectionLimitsParsed,
  ICollection,
  SetCollectionPermissionsBody,
  SetCollectionPermissionsParsed,
  TransferCollectionBody,
  TransferCollectionParsed,
  EffectiveCollectionLimitsResponse,
  DestroyCollectionBody,
  DestroyCollectionParsed,
  SetTransfersEnabledBody,
  SetTransfersEnabledParsed,
  NextSponsoredResponse,
  LastTokenIdResultDto,
  GetStatsResponse,
  GetCollectionTokensResponse,
  AddToAllowListBody,
  AddToAllowListResponse,
  RemoveFromAllowListBody,
  RemoveFromAllowListResponse,
  AdminlistResponse,
  AddCollectionAdminBody,
  AddCollectionAdminParsed,
  RemoveCollectionAdminBody,
  RemoveCollectionAdminParsed,
  SetSponsorshipBody,
  SetSponsorshipParsed,
  ConfirmSponsorshipBody,
  ConfirmSponsorshipParsed,
  AllowedResponse,
  RemoveSponsorshipBody,
  RemoveSponsorshipParsed,
  AllowListQuery,
  CollectionIdQuery,
  NextSponsoredQuery,
  LastTokenIdQuery,
  AllowedQuery,
  TotalSupplyResponse,
  PropertyPermissionsQuery,
  AllowListBodyResult,
  Method,
} from '../types';
import { createMutationEx } from '../common/mutation';

export class Collection extends Section implements ICollection {
  readonly setProperties = createMutationEx<
    SetCollectionPropertiesBody,
    SetCollectionPropertiesParsed
  >(this.client, Method.POST, `${this.path}/properties`);

  readonly deleteProperties = createMutationEx<
    DeleteCollectionPropertiesBody,
    DeleteCollectionPropertiesParsed
  >(this.client, Method.DELETE, `${this.path}/properties`);

  readonly setPropertyPermissions = createMutationEx<
    SetPropertyPermissionsBody,
    SetPropertyPermissionsParsed
  >(this.client, Method.POST, `${this.path}/property-permissions`);

  readonly properties = (
    args: CollectionIdQuery,
  ): Promise<CollectionPropertiesResponse> => this.query('properties', args);

  readonly tokens = (
    args: CollectionIdQuery,
  ): Promise<GetCollectionTokensResponse> => this.query('tokens', args);

  readonly propertyPermissions = (
    args: PropertyPermissionsQuery,
  ): Promise<PropertyPermissionsResponse> =>
    this.query('property-permissions', args);

  /**
   * @deprecated Use create method
   */
  readonly creation = createMutationEx<
    CreateCollectionBody,
    CreateCollectionParsed
  >(this.client, Method.POST, this.path);

  readonly create = createMutationEx<
    CreateCollectionBody,
    CreateCollectionParsed
  >(this.client, Method.POST, this.path);

  readonly getLimits = (
    args: CollectionIdQuery,
  ): Promise<EffectiveCollectionLimitsResponse> => this.query('limits', args);

  readonly setLimits = createMutationEx<
    SetCollectionLimitsBody,
    SetCollectionLimitsParsed
  >(this.client, Method.POST, `${this.path}/set-limits`);

  readonly get = (
    args: CollectionIdQuery,
  ): Promise<CollectionInfoWithSchemaResponse> => this.query('', args);

  readonly transfer = createMutationEx<
    TransferCollectionBody,
    TransferCollectionParsed
  >(this.client, Method.PATCH, `${this.path}/transfer`);

  readonly destroy = createMutationEx<
    DestroyCollectionBody,
    DestroyCollectionParsed
  >(this.client, Method.DELETE, this.path);

  readonly stats = (): Promise<GetStatsResponse> => this.query('stats');

  readonly setTransfersEnabled = createMutationEx<
    SetTransfersEnabledBody,
    SetTransfersEnabledParsed
  >(this.client, Method.POST, `${this.path}/transfers-enabled`);

  readonly setPermissions = createMutationEx<
    SetCollectionPermissionsBody,
    SetCollectionPermissionsParsed
  >(this.client, Method.POST, `${this.path}/permissions`);

  readonly nextSponsored = (
    args: NextSponsoredQuery,
  ): Promise<NextSponsoredResponse> => this.query('next-sponsored', args);

  readonly lastTokenId = (
    args: LastTokenIdQuery,
  ): Promise<LastTokenIdResultDto> => this.query('last-token-id', args);

  readonly allowList = (args: AllowListQuery): Promise<AllowListBodyResult> =>
    this.query('allow-list', args);

  readonly allowed = (args: AllowedQuery): Promise<AllowedResponse> =>
    this.query('allowed', args);

  readonly addToAllowList = createMutationEx<
    AddToAllowListBody,
    AddToAllowListResponse
  >(this.client, Method.POST, `${this.path}/add-to-allow-list`);

  readonly removeFromAllowList = createMutationEx<
    RemoveFromAllowListBody,
    RemoveFromAllowListResponse
  >(this.client, Method.POST, `${this.path}/remove-from-allow-list`);

  readonly admins = (args: CollectionIdQuery): Promise<AdminlistResponse> =>
    this.query('admins', args);

  readonly addAdmin = createMutationEx<
    AddCollectionAdminBody,
    AddCollectionAdminParsed
  >(this.client, Method.POST, `${this.path}/admins`);

  readonly removeAdmin = createMutationEx<
    RemoveCollectionAdminBody,
    RemoveCollectionAdminParsed
  >(this.client, Method.DELETE, `${this.path}/admins`);

  readonly setSponsorship = createMutationEx<
    SetSponsorshipBody,
    SetSponsorshipParsed
  >(this.client, Method.POST, `${this.path}/sponsorship`);

  readonly confirmSponsorship = createMutationEx<
    ConfirmSponsorshipBody,
    ConfirmSponsorshipParsed
  >(this.client, Method.POST, `${this.path}/sponsorship/confirm`);

  readonly removeSponsorship = createMutationEx<
    RemoveSponsorshipBody,
    RemoveSponsorshipParsed
  >(this.client, Method.DELETE, `${this.path}/sponsorship`);

  readonly totalSupply = (
    args: CollectionIdQuery,
  ): Promise<TotalSupplyResponse> => this.query('total-supply', args);
}
