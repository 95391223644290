import { Section } from '../common';
import {
  BalanceTransferBody,
  BalanceTransferParsed,
  AllBalancesResponse,
  GetBalanceQuery,
  Method,
  IBalance,
} from '../types';
import { createMutationEx } from '../common/mutation';

export class Balance extends Section implements IBalance {
  readonly transfer = createMutationEx<
    BalanceTransferBody,
    BalanceTransferParsed
  >(this.client, Method.POST, `${this.path}/transfer`);

  readonly get = (args: GetBalanceQuery): Promise<AllBalancesResponse> =>
    this.query('', args);
}
