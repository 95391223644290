/* eslint-disable max-classes-per-file */
import {
  ExtrinsicResultResponse,
  SubmitResultResponse,
  SubmitTxBody,
  UnsignedTxPayloadResponse,
  BuildBatchPayloadsResponse,
  IMutation,
  Method,
  FeeBodyResponse,
  IBaseClient,
  MutationOptions,
  IMutationEx,
  WithOptionalAddress,
} from '../types';
import {
  isUnsignedTxPayloadResponse,
  isSubmitTxBody,
  sleep,
  addAddress,
} from './utils';

export class MutationAbstract<A extends WithOptionalAddress, R>
  implements IMutation<A, R>
{
  public readonly url: string;

  constructor(
    private readonly client: IBaseClient,
    private readonly method: Method,
    private readonly path: string,
  ) {
    this.url = `${this.client.options.baseUrl}/${this.path}`;
  }

  async buildBatch(
    args: A[],
    options?: MutationOptions,
  ): Promise<BuildBatchPayloadsResponse> {
    const data = args.map((a) => addAddress(this.client.options, a));

    const response = await this.client.instance({
      method: this.method,
      url: this.url,
      params: { ...options, use: 'BuildBatch' },
      data,
    });

    return response.data;
  }

  async build(
    args: A,
    options?: MutationOptions,
  ): Promise<UnsignedTxPayloadResponse> {
    const data = addAddress(this.client.options, args);

    const response = await this.client.instance({
      method: this.method,
      url: this.url,
      params: { ...options, use: 'Build' },
      data,
    });

    return response.data;
  }

  async getFee(
    args:
      | A
      | UnsignedTxPayloadResponse
      | SubmitTxBody
      | Array<A | UnsignedTxPayloadResponse | SubmitTxBody>,
  ): Promise<FeeBodyResponse> {
    const response = await this.client.instance({
      method: this.method,
      url: this.url,
      params: { use: 'GetFee' },
      data: args,
    });

    return response?.data as FeeBodyResponse;
  }

  async sign(
    args: A | UnsignedTxPayloadResponse,
    options?: MutationOptions,
  ): Promise<SubmitTxBody> {
    const unsigned = isUnsignedTxPayloadResponse(args)
      ? args
      : await this.build(args, options);

    const { signerPayloadJSON } = unsigned;
    const { signature } = await this.client.extrinsic.sign(
      unsigned,
      options?.signer,
    );
    return { signature, signerPayloadJSON };
  }

  async submit(
    args: A | UnsignedTxPayloadResponse | SubmitTxBody,
    options?: MutationOptions,
  ): Promise<SubmitResultResponse> {
    const submitTxArguments = isSubmitTxBody(args)
      ? args
      : await this.sign(args, options);

    const response = await this.client.instance({
      method: this.method,
      url: this.url,
      params: { ...options, use: 'Submit' },
      data: submitTxArguments,
    });

    return response.data;
  }

  async submitWatch(
    args: A | UnsignedTxPayloadResponse | SubmitTxBody,
    options?: MutationOptions,
  ): Promise<SubmitResultResponse> {
    const submitTxArguments = isSubmitTxBody(args)
      ? args
      : await this.sign(args, options);

    const response = await this.client.instance({
      method: this.method,
      url: this.url,
      params: { ...options, use: 'SubmitWatch' },
      data: submitTxArguments,
    });

    return response.data;
  }

  async submitWaitResult(
    args: A | UnsignedTxPayloadResponse | SubmitTxBody,
    options?: MutationOptions,
  ): Promise<ExtrinsicResultResponse<R>> {
    const { hash } = await this.submitWatch(args, options);
    let checkStatusResult: ExtrinsicResultResponse<R> | undefined;
    let i = 0;
    while (
      (!checkStatusResult || !checkStatusResult?.isCompleted) &&
      i <= this.client.options.maximumNumberOfStatusRequests
    ) {
      i += 1;
      // eslint-disable-next-line no-await-in-loop
      checkStatusResult = await this.client.extrinsic.status({ hash });
      if (checkStatusResult.isCompleted || checkStatusResult.error) {
        return checkStatusResult;
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(this.client.options.waitBetweenStatusRequestsInMs);
    }
    throw new Error();
  }
}

class Mutation<A, R> extends MutationAbstract<A, R> {}

export function createMutationByInstance<A, R>(
  mutation: Mutation<A, R>,
): IMutationEx<A, R> {
  const submitWaitResult = (
    args: A | UnsignedTxPayloadResponse | SubmitTxBody,
    options?: MutationOptions,
  ) => mutation.submitWaitResult(args, options);

  submitWaitResult.build = mutation.build.bind(mutation);
  submitWaitResult.buildBatch = mutation.buildBatch.bind(mutation);
  submitWaitResult.getFee = mutation.getFee.bind(mutation);
  submitWaitResult.sign = mutation.sign.bind(mutation);
  submitWaitResult.submit = mutation.submit.bind(mutation);
  submitWaitResult.submitWatch = mutation.submitWatch.bind(mutation);
  submitWaitResult.submitWaitResult = mutation.submitWaitResult.bind(mutation);

  return submitWaitResult as IMutationEx<A, R>;
}

export function createMutationEx<A, R>(
  client: IBaseClient,
  method: Method,
  path: string,
): IMutationEx<A, R> {
  const mutation = new Mutation<A, R>(client, method, path);

  return createMutationByInstance(mutation);
}
